:root {
  --default-color: #3E9A36;
  --text-color: #344563;
  --form-radius: 0;
  --form-height: 64px;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.slick-track {
  width: 100%;
  margin: 0 !important;
}


// settings for slick dots
.slick-slider {

  .slick-list {
    * {
      outline: none !important;
    }
  }

  .slick-arrow {

    &.slick-next, &.slick-prev {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 40px;
      min-width: 40px;
      height: 40px;
      display: flex;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0);
      transition: .3s all;
      top: 50%;
      opacity: 1;

      //&:hover {
      //    background-color: #EBECF0;
      //}
    }

    &:before {
      display: block;
      content: "";
      width: 20px;
      height: 20px;
      border-left: 2px solid white;
      border-top: 2px solid white;
      background-color: transparent;
      margin: auto;
    }


    &.slick-next {
      z-index: 30;
      right: 35px;
      padding-right: 2px;

      &:before {
        transform: rotate(130deg) skew(-1deg, -1deg);

      }
    }

    //skew(7deg, 7deg);
    &.slick-prev {
      z-index: 31;
      left: 35px;
      padding-left: 6px;

      &:before {
        transform: rotate(-45deg) skew(-1deg, -1deg);
      }
    }

  }

  //9643
  .slick-dots {
    li {
      padding: 0 5px;
      width: 30px;
      min-width: 30px;
      height: 4px;

      button {
        padding: 0;
        opacity: 1;
        width: 30px;
        min-width: 30px;
        height: 4px;
        background-color: #D2D2D2;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: var(--default-color);

        }
      }
    }
  }
}