.services {
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 27px;

    @media (max-width: 768px) {
      grid-gap: 17px;
    }
  }
}