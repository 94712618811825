.header {
  z-index: 999;
  background-color: white;
  position: sticky;
  top: 0;
  //border-bottom: 1px solid #dfe1e6;


  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 84px;
    border-bottom: 1px solid  #dfe1e6;
    margin: 0 -15px;
    padding: 15px;
  }

  .toggleMobile{
    display: none;
    cursor: pointer;


    @media (max-width: 990px) {
      display: block;
    }
  }


  .headerNav {
    height: 84px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 40px;
      align-items: center;

    @media (max-width: 990px) {
      display: none;
    }

    > ul {
      display: grid;
      grid-template-columns:  repeat(5, auto);
      grid-gap: 20px;
      list-style: none;
      align-items: center;

      li {
        font-style: normal;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #091E42;
      }
    }
  }


}