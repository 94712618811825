
.blockHeader {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 28px;
}

.blockHeaderTitle {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #172B4D;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 34px;
    line-height: 59px;
  }
}

.blockHeaderDescription {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #505F79;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
}