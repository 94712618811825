
.select {
  border: none;
  border-bottom: 2px solid #A5ADBA;
  height: var(--form-height);
  background-color: white;
  appearance: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: var(--text-color);
  display: block;
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 45px;
}

.selectWrapper {
  position: relative;

  label {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    left: 20px;
    top: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6F7B8C;
  }

}

.selectMain {
  position: relative;


  svg {
    position: absolute;
    pointer-events: none;
    right: 20px;
    top: calc(50px - 10px);
  }
}