.mainSlider{

  .mainSliderImage{
    height: 450px;
    background-size: cover;
    background-position: bottom center;

    @media (max-width: 767px) {
      height: 390px;
    }
  }
}