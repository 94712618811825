
.languageSwitcher {
  .languageSwitcherSelected {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #091E42;
    margin-right: 3px;
    transition: .3s all;

    &:hover {
      color: #3E9A36;

        path{
          fill: #3E9A36;
      }

    }
  }

  .languageSwitcherListWrapper {
    position: relative;

  }

  .languageSwitcherList {
    margin-top: 10px;
    margin-left: -10px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    left: 0;

    background-color: white;
    list-style: none;
    box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.1);


    li {
      padding: 20px;
      cursor: pointer;
      transition: .3s all;

      &:hover {
        background-color: #E8F3E6;
        color: #3E9A36;
      }
    }

  }
}
