.mobileMenu {
  position: fixed;
  top: 84px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: white;
  width: 100%;
  height: calc(100% - 84px);
}

.mobileMenuWrapper {
  overflow: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    .mobileMenuItem {
      padding: 29px 24px;
      border-bottom: 1px solid #dfe1e6;
      font-weight: 400;
      font-size: 21px;
      line-height: 26px;
      color: #172B4D;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.buttonWrapper {
  padding: 24px;

  button {
    width: 100%;
  }
}