

.double-carousel__carousel {
  background: white;
  overflow: hidden;

  *{
    line-height: 0;
  }
  .double-carousel__single {
    //margin-bottom: 6px;
    //padding: 5px;

    .slick-slider{
      .slick-arrow{
        background-color: rgba(238, 238, 238, 0.5) ;

        &:before{
          border-left-color: #30373E;
          border-top-color: #30373E;
        }

        &:hover{
          //background-color: $color-default;

          &:before{
            border-left-color: white;
            border-top-color: white;
          }
        }
      }
    }

    .single__image {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 458px;
    }
  }




  .double-carousel__multiple {
    overflow: hidden;
    height: 110px;
    margin: 0 -5px;




    .multiple__image {
      position: relative;
      height: 110px;
      background-size: cover;
      background-position: center;
      width: 100%;
      //padding: 10px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .3);
        transition: .3s all;
      }

      &:hover {
        &:before {
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }
  }
}