
.mobileLanguageSwitcher{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 48px);
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #dfe1e6;

  .languageItem{
    width: 48px;
    min-width: 48px;
    height: 48px;
    background: #EBECF0;
    border-radius: 50%;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #172B4D;
    text-transform: uppercase;

    &.active{
      background: #E8F3E6;
      color:#3E9A36;
    }
  }
}