.layout {

  .layoutAbout {
    padding-bottom: 150px;

    @media (max-width: 768px) {
      padding-bottom: 50px;
    }
  }

  .layoutPhotoGallery{
    padding-bottom: 150px;

    @media (max-width: 768px) {
      padding-bottom: 50px;
    }
  }

  .layoutRooms {
    padding-bottom: 160px;

    @media (max-width: 768px) {
      padding-bottom: 50px;
    }
  }

  .layoutServices {
    padding-bottom: 160px;

    @media (max-width: 768px) {
      padding-bottom: 50px;
    }
  }

  .layoutLocation {
    padding-bottom: 160px;

    @media (max-width: 768px) {
      padding-bottom: 0;
    }
  }
}



