.button{
  appearance: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  background-color: var(--default-color);
  border: 1px solid var(--default-color);
  border-radius: var(--form-radius);
  color: white;
  height: 44px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;


  &.outline{
    color: var(--default-color);
    background-color: rgba(0,0,0,0);
  }

  &.small{
    height: 32px;
  }

  &.large{
    height: 64px;
  }


}