
.location {
  margin-bottom: 27px;

  img {
    object-fit: cover;
    width: 100%;
    display: block;
  }

  .locationImageMobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .locationImageDesktop {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.locationWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 58px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.locationText {
  margin-bottom: 60px;
  h3 {
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    margin-bottom: 33px;

    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 34px;
      line-height: 59px;
      margin-bottom: 14px;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #344563;

    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }
  }

  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #344563;

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
}

.locationImageWrapper {
  margin: auto -15px;
}


