.about {
  position: relative;
  background-size: cover;
  min-height: 754px;

  @media (max-width: 767px) {
    background-image: none !important;
  }

  h2{
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    color: #091E42;
    margin-bottom: 24px;


    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 34px;
      line-height: 59px;
      letter-spacing: 0.2px;
    }
  }

  .aboutGradient{
    position: absolute;
    height: 100%;
    width: 80%;
    background: linear-gradient(89.76deg, #FFFFFF 0.25%, rgba(255, 255, 255, 0) 99.84%);
  }

  .aboutText {
    padding-top: 54px;
    padding-bottom: 54px;
    max-width: 560px;
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;

    letter-spacing: 0.2px;
    color: #344563;


    ul{
      margin-left: 15px;
    }

    @media (max-width: 767px) {
      max-width: none;
    }

    p {
        margin-bottom: 24px;
    }

    img{
      display: none;
        width: 100%;
        height: auto;

      @media (max-width: 767px) {
        display: block;
        //object-fit: cover;
      }
    }
  }


}