
.modal-core {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 30, 66, 0.36);
  z-index: 999;
  overflow: auto;
  display: flex;
  align-items: center;
  padding: 15px;
}

/* Modal Content */
.modal-core__content {
  margin: auto;
  position: relative;
  z-index: 2000;
  max-width: 100%;
  width: 900px;

}

.modal-core__body {
  height: 100%;
  position: relative;
  z-index: 500;

}

.modal-core-close {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modal-core__button-close {
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
  transition: 0.3s all;
  width: 30px;
  height: 30px;
  display: flex;
  margin-right: -10px;
  justify-content: center;
  align-items: center;
  z-index: 2000;


}
