
.room__center {

}

.room {

}


.room__wrapper {
  margin: 0 -20px;
}

.room__carousel {
  a {
    text-decoration: none;
  }
}

.room__outer {
  padding: 20px;
}

.room__item {
  position: relative;
  height: 370px;
  width: 100%;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.33);
    z-index: 2;
  }
}

.room__text {
  margin-top: 8px;


  h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #091E42;
  }

  .room__descr {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #6F7B8C;
  }
}

.roomImage {
  background-size: cover;
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 100%;
}