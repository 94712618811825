.search-form {
  background-color: #F4F5F7;

}

.search-form-wrapper{
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: flex-end;
  padding: 40px 0;
  grid-gap: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  .search-form-fields{

    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr;
    grid-gap: 16px;


    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.searchButton{
  button{
    width: 100%;
  }
}