.datepickerWrapper {
  position: relative;

  label {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    left: 20px;
    top: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6F7B8C;
  }
}

.datepickerMain {
  position: relative;

  //.react-datepicker__input-container{
  input {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 45px;

    width: 100%;
    display: block;
    height: var(--form-height);
    border: none;
    border-bottom: 2px solid #A5ADBA;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #091E42;
  }


  //}

  > svg {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 12px);
    right: 20px;
  }

}