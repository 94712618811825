.photo-gallery {

}


.photo-gallery-wrapper {
  .slick-slide {
    transition: .3s all;
    transform: scale(.9);
    &.slick-center {
      transform: scale(1);

      @media (max-width: 768px) {
        transform: scale(.97);
      }
    }
  }
}

.photo-gallery-outer {

}

.photo-gallery-item {

}

.photo-gallery-image {
  object-fit: cover;
  width: 100%;
}

