.footer {
  height: 450px;
  background-size: cover;
  position: relative;
  color: white;

  .address {
    white-space: break-spaces;
    margin-bottom: 20px;
  }

  .email{
    margin-bottom: 20px;
    //margin-bottom: 30px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    justify-content: flex-start;

    a{
      color: white;
      text-decoration: none;
    }
  }


  .footerWrapper {
    padding: 50px 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

  }


  .footerInfo {
    z-index: 2;
    position: relative;

    line-height: 25px;
    font-weight: 600;
    font-size: 16px;
  }

}

.footerName {
  position: relative;
  z-index: 3;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;

}


.receptionLabel {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.receptionPhone {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.reservationPhone {
  a{
    color: white;
    text-decoration: none;

  }
}


