.services {
  display: block;
  text-decoration: none;

  img {
    width: 100%;
    object-fit: contain;
    margin-bottom: 9px;
    height: 216px;
  }

  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    color: #091E42;
    text-align: center;

    @media (max-width: 768px) {
     font-size: 14px;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  div {
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.2px;
    color: #6F7B8C;
    text-align: center;
  }

}